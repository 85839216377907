body {
  font-family: "Libre Baskerville";
  background: hsl(0, 100%, 33%);
}

.username {
    color: white;
    font-size: 25px;
    letter-spacing: 2px;
    position:absolute;
    right:20px;
}

.flexbox-title {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 100px;
  gap: 20px;
}

.title {
  color: white;
  font-size: 100px;
  font-family: "Libre Baskerville";
  font-style: italic;
  letter-spacing: 5px;
  text-align:center;
}

.fpage-image {
  height: 300px;
}

.flexbox-info {
  padding: 50px;
  display: flex;
  justify-content: center;
  gap: 135px;
  flex-wrap:wrap;
}

.flexbox-info > * {
  font-size: 30px;
  font-family: "Libre Baskerville";
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 300px;
  gap: 20px;
  letter-spacing: 3px;
}

.info-image {
  height: 200px;
}

.description {
  font-size: 20px;
  text-align: center;
  letter-spacing: 0px;
}

.navbar {
  justify-content: center;
  background: rgb(30, 30, 30);
  font-family: "Libre Baskerville";
  position:relative;
}

.navbar-sections {
  width: 380px;
  display: flex;
  align-items: center;
  gap: 50px;
}

.navbar button {
  border: none;
  background: rgb(30, 30, 30);
  color: white;
  font-size: 25px;
  letter-spacing: 2px;
  transition: 0.25s;
  transition-timing-function: cubic-bezier(0.7, -0.83, 0.43, 1.74);
}

.navbar button:hover {
  transform: scale(1.2);
}

.login-page {
  display: flex;
  align-items: center;
  color: white;
  flex-direction: column;
  gap: 20px;
  padding: 30px;
}

.white-button {
    background: white;
    border:1px solid lightgray;
    border-radius:5px;
    letter-spacing: 1px;
    color: rgb(30, 30, 30);
}

.red-button {
    background: hsl(0, 100%, 33%);
    border-radius:5px;
    border: 1px solid hsl(0, 100%, 25%);
    letter-spacing: 1px;
    color: white;
}

.red-button:hover {
    background: hsl(0, 100%, 29%);
}

.white-button:hover {
    background: rgb(241, 241, 241);
}

.restaurant-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, 425px);
    justify-content:center;
    grid-gap:40px;

}

.restaurant-card {
    background:rgb(243, 243, 243);
    padding:10px;
    border: solid 1px lightgray;
    border-radius:5px;
    display:flex;
    flex-direction:column;
}

.flexbox-inputs {
    display:flex;
    justify-content:center;
    gap:20px;

}

.input-section-container {
  grid-column: 1 / -1;
  display: flex;
  gap:max(30px);
  justify-content:center;
  
}

.input-section {
    display:flex;
    gap:10px;
    width:30%;
    flex-direction:column;
    align-items:center;
}



.input-section *{
    width:100%;
    height: 50px;
}

.search-button {
    font-size:20px;
    width:max(100px,100%);
}

.restaurant-page-info {
    display:flex;
    flex-direction:column;
    align-items:center;
    justify-content:center;
    background:white;
    border: 1px solid lightgray;
    border-radius:5px;
    width:70%;
    height:300px;
    gap:30px;
}

@media (max-width: 600px) {
  .fpage-image {
    height: 150px;
  }

  .title {
    font-size: 75px;
  }

}

@media (max-width: 600px)
{
  .navbar-sections {
    width:100%;
    display:flex;
    flex-direction:column;
    
  }

  .navbar-section {
    border: 10px solid black;
  }

}









